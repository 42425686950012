<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text">
          امتحانات
        </h1>
        <h2 class="text-center subtitle-5 black--text">
          {{ this.$route.params.studentName }}
        </h2>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-data-table :loading="table.loading" loading-text="جاري التحميل ... الرجاء الانتظار" :headers="headers"
              :items="studentsData" :search="table.search" :items-per-page="10" item-key="account_id"
              class="elevation-1" :footer-props="{
                showFirstLastPage: true,
              }">
              <template slot="item.exams_id" slot-scope="props"> {{ props.index + 1 }} </template>
              <template v-slot:item.exams_name="{ item }">
                <router-link
                  :to="'/exams/examSubject/' + $route.params.class_school_id + '/' + item._id + '/' + item.exams_name">
                  {{ item.exams_name }} </router-link>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="primary" class="ml-2" small
                  @click="$router.push('/exams/examSubject/' + $route.params.class_school_id + '/' + item._id + '/' + item.exams_name)">
                  اضافة جدول </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import api from '@/api/api'

export default {
  data() {
    return {
      open: [4],
      items: [],

      table: {
        loading: false,
        search: null,
        imageUrlForShow: null,
        showImageDailog: false,
      },
      headers: [
        {
          text: '#',
          align: 'start',
          width: 1,

          value: 'exams_id',
        },
        {
          text: 'وصف الامتحان',
          value: 'exams_name',
        },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      studentsData: [],
    }
  },

  async mounted() {
    this.getStudentData()
  },
  methods: {
    async getStudentData() {
      this.table.loading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await api.getOneExams(this.$route.params.classSchoolId, study_year)
      if (response.status === 401) {
        this.table.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.studentsData = response.data.results
        // this.leader_select = response.data.results
      }
    },

    isStudentSuccess(degree, maxDegree) {
      if (degree >= maxDegree / 2) {
        return true
      }

      return false
    },
  },
}
</script>

<style scoped>
.exams_subjectList {
  padding: 2px 10px;
}
#customers {
  border-collapse: collapse;
  width: 100%;
}
#customers tbody {
  text-align: center;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #00acc1;
  color: white;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team"},[_c('v-card',{staticClass:"primary-shade--light pa-3"},[_c('h1',{staticClass:"text-center mb-3 subtitle-4 black--text"},[_vm._v(" تسديدات الطالب : "+_vm._s(_vm.$route.params.student_name)+" ")]),_c('h3',{staticClass:"text-center mb-3 subtitle-5 black--text"},[_vm._v(" الفاتورة: "+_vm._s(_vm.$route.params.name))]),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3","sm":"3","cols":"12"}},[_c('v-text-field',{attrs:{"label":"البحث","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.table.loading,"loading-text":"جاري التحميل ... الرجاء الانتظار","headers":_vm.headers,"items":_vm.driversData,"search":_vm.table.search,"items-per-page":10,"item-key":"_id","footer-props":{
            showFirstLastPage: true,
          }},scopedSlots:_vm._u([{key:"item._id",fn:function(props){return [_vm._v(" "+_vm._s(props.index + 1)+" ")]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [(item.type === 'salary')?_c('span',[_vm._v("المبلغ")]):(item.type === 'payment')?_c('span',[_vm._v("تسديد")]):_c('span',[_vm._v("خصم")])]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.amount))+" ")]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt)+" ")]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogData.open),callback:function ($$v) {_vm.$set(_vm.dialogData, "open", $$v)},expression:"dialogData.open"}},[_c('v-toolbar',{attrs:{"color":_vm.dialogData.color,"dense":""}}),_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" "+_vm._s(_vm.dialogData.bodyText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogData.open = false}}},[_vm._v(" تم ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }